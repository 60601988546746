.swiperSlideWrapper {
  padding-bottom: 1rem !important;
  .swiper-slide-duplicate-next > div {
    background-color: #fff !important;
    color: black !important;
  }
}

.swiperSlide {
  &-active {
    background-color: #bd1701 !important;
  }
}
.swiperSlide :global(.swiper-slide-next) {
  color: purple !important;
}

.swiperButtonNext {
  right: calc(50% - 242.5px) !important;
  top: calc(50% - (var(--swiper-navigation-size) / 2)) !important;
  left: auto;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 1rem;

  svg {
    color: white !important;
  }
}

.swiperButtonPrev {
  left: calc(50% - 242.5px) !important;
  top: calc(50% - (var(--swiper-navigation-size) / 2)) !important;
  right: auto;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 1rem;

  svg {
    color: white !important;
  }
}

.swiperPaginationCustom {
  span {
    background-color: white !important;
    margin: 0 8px !important;
  }
}

.swiperButtonNext::after,
.swiperButtonPrev::after {
  content: '' !important;
}

@media (max-width: 640px) {
  .swiperButtonNext {
    right: calc(50% - 175.5px) !important;
    left: auto;
  }

  .swiperButtonPrev {
    left: calc(50% - 175.5px) !important;
    right: auto;
  }
  .swiperPaginationCustom {
    width: 100% !important;
    bottom: 0px !important;
  }
}

@media (max-width: 321px) {
  .swiperSlideWrapper {
    margin-bottom: 20px;
  }

  .swiperSlide {
    width: 17.1rem !important;
  }

  .swiperSlide > div {
    width: 16.1rem !important;
  }

  .swiperButtonNext {
    right: calc(50% - 134px) !important;
    left: auto;
    z-index: 99;
  }

  .swiperButtonPrev {
    left: calc(50% - 134px) !important;
    right: auto;
    z-index: 99;
  }

  .swiperPaginationCustom {
    width: 100% !important;
    bottom: 0px !important;
  }
}
