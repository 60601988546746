.checkboxInput[type=checkbox] {
  width: 0;
  height: 0;

  border: none;
  box-shadow: none;
}

.checkboxInput[type=checkbox]+label {
  height: 3rem;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 10px 5px;
  border: 1px solid #e4e4e7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  cursor: pointer;
  user-select: none;
}

.checkboxInput[type=checkbox]:checked+label {
  border: 2px solid #017fcb;
}
