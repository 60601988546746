.reinforcementLayer {
  &:after {
    content: '';
    display: block;
    background: #c9d7e9;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 1px);
    height: 2px;
    z-index: 1;
  }

  position: relative;
  text-align: center;
  z-index: 2;
  margin-bottom: 4rem;
  margin-top: 5.5rem;

  span {
    padding: 0 1rem;
    position: relative;
    display: inline-block;
    z-index: 3;
    font-size: 1.125rem;
  }
}
