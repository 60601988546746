.Iframe {
  width: 100% !important;
  border: none !important;
  overflow: hidden !important;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

.Iframe::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none !important;
}

html:has(.test) {
  overflow: hidden;
}
